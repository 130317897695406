


.bg-container {
  /* background-image: url('C:\Users\DELL\Desktop\final project\final-project\src\images\logindoctor.png'); */
  background-size: cover;
  background-position: center;
  min-height: 68vh;
  width: 100%;
  padding: 0;
  position: relative;
}
 
.bg-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(0px);
  z-index: 1;
}
.form-wrapper {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}
 
 
.form-container {
  position: relative;
  z-index: 2;
}
 
.form-container form {
  width: 100%;
  max-width: 600px;
  padding: 40px;
  background-color: rgba(238, 238, 238, 0.9);
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
 
@media (max-width: 768px) {
  .form-container form {
    max-width: 100%;
    margin: 0 20px;
    padding: 30px;
    min-height: 400px;
  }
}
 
@media (min-width: 1200px) {
  .form-container form {
    height: 50vh;
    max-width: 75%;
  }
}
 
buttons {
  font-size: 1.3rem;
  padding: 12px;
  width: 28%;
}
 
.login-form, .otp-form, .forgot-password-form, .reset-password-form {
  max-width: 600px;
}
 
 
 
.otp-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
  margin: 20px 0;
}
 
.otp-input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid #4384c9;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
 
.otp-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0px 4px 6px rgba(0, 123, 255, 0.25);
}
 
@media (max-width: 768px) {
  .otp-container {
    justify-content: space-between;
    gap: 5px;
  }
 
  .otp-input {
    width: 45px;
    height: 45px;
    font-size: 1.4rem;
  }
}
 
@media (max-width: 576px) {
  .otp-container {
    justify-content: space-between;
    gap: 4px;
  }
 
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
}
 
 
.forgot-password-container {
  display: flex;
  justify-content: flex-end;
}
 
.forgot-password-form {
  background-color: rgba(238, 238, 238, 0.9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  margin-right: 20px;
  z-index: 2;
}
 
.reset-password-container {
  display: flex;
  justify-content: flex-end;
}
 
.reset-password-form {
  background-color: rgba(238, 238, 238, 0.9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  margin-right: 20px;
  z-index: 2;
}
 
@media (max-width: 768px) {
  .forgot-password-form,
  .reset-password-form {
    margin-right: 10px;
  }
}
 
 
.auth-toggle {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
 
.auth-link {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
  margin: 0 15px;
}
 
.auth-link.active {
  color: #0056b3;
  border-bottom: 2px solid #0056b3;
}
 
.divider {
  margin: 0 8px;
  font-size: 1.8rem;
}
 
.doctor-login-link {
    position: relative;
    padding-bottom: 50px;
}
 
.text-link {
    position: absolute;
    top: 0;
    right: 0;
}
 
 
.doctor-login-link a {
  color: #0063cc;
  text-decoration: none;
  font-weight: bold;
  text-decoration: underline;
}
 
 
.login-box {
  padding-top: 40px;
}
 
.reg-box {
  padding-top: 64px;
}
 
.plogin-box {
  padding-top: 64px;
}
 
.preg-box {
  padding-top: 64px;
}