/* .navbar {
    background-color: #0e3a47;
}
.navbar-collapse {
  transition: max-height 0.3s ease;
}

.navbar-collapse.show {
  max-height: 500px;
}

.navbar-toggler-icon {
  color: white;
}

.navbar-nav .nav-link,
.navbar-nav .nav-item .dropdown-toggle {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.dropdown-item:hover {
  color: white;
}

img {
    border-radius: 50%;
} */






.navbar {
  background-color: #0e3a47;
}

.navbar-collapse {
  transition: max-height 0.3s ease;
}

.navbar-collapse.show {
  max-height: 500px;
}

.navbar-toggler-icon {
  color: white;
}

.navbar-nav .nav-link,
.navbar-nav .nav-item .dropdown-toggle {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
}

.navbar-nav .active-link {
  color: #17a2b8;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-item .dropdown-toggle:hover {
  color: #0f9ba1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.dropdown-menu {
  background-color: #0e3a47;
  border: none;
}

.dropdown-item {
  color: white;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;
}

.dropdown-item:hover {
  color: #0f9ba1;
  background-color: rgba(255, 255, 255, 0.1);
}

img {
  border-radius: 50%;
}

.navbar-nav.d-lg-none .nav-link:hover {
  color: #0f9ba1;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
