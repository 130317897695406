/* For WebKit browsers like Chrome, Edge, Safari */
.row::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
  }
  
  .row::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .row::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
    border: 2px solid #f1f1f1; /* Add some padding around the thumb */
  }
  
  .row::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color when hovered */
  }
  
  /* For Firefox */
  .row {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #4C585B #D7EAF0; /* Thumb color and track color */
  }
  
  .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

input:disabled + .slider {
  background-color: #bfbfbf;
  cursor: not-allowed;
}

input:disabled + .slider:before {
  background-color: #e0e0e0;
}
