
.contact-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-card h3 {
  font-size: 20px;
  color: #34495e;
  margin-bottom: 10px;
}

.contact-card p {
  font-size: 16px;
  color: #7f8c8d;
}

.contact-card a {
  color: #3498db;
  text-decoration: none;
}

.contact-card a:hover {
  text-decoration: underline;
}


.contact-us {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to bottom, #ffffff, #cdeff1);
  padding: 40px 0;
  color: #333;
}

.contact-header {
  text-align: center;
  padding-bottom: 30px;
}

.contact-header h1 {
  font-size: 36px;
  font-weight: bold;
  color: #2c3e50;
}

.contact-header p {
  font-size: 18px;
  color: #7f8c8d;
  max-width: 600px;
  margin: 0 auto;
}

.form-map {
  margin-bottom: 30px;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  color: #2c3e50;
}

a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
  color: #1e7fb9;
}

.contact-form-section {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.contact-form-section:hover {
  transform: translateY(-5px);
}

.contact-form h2 {
  font-size: 28px;
  font-weight: 700;
  color: #34495e;
  text-align: center;
  margin-bottom: 20px;
}

.contact-form .form-control {
  margin-bottom: 15px;
  padding: 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #d1d8e0;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form .form-control:focus {
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.contact-form button {
  background-color: #3498db;
  color: white;
  font-size: 18px;
  border: none;
  padding: 12px 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.contact-form button:hover:not(:disabled) {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.social-media {
  text-align: center;
  margin-top: 40px;
}

.social-media h3 {
  font-size: 24px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 20px;
}

.social-links a {
  margin: 0 15px;
  color: #333;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.2);
}

.social-links .facebook:hover {
  color: #3b5998;
}

.social-links .twitter:hover {
  color: #1da1f2;
}

.social-links .instagram:hover {
  color: #e1306c;
}

.social-links .linkedin:hover {
  color: #0077b5;
}

.map-section {
  padding-left: 20px;
}

.map-container iframe {
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Footer Links */
.faq {
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
  color: #7f8c8d;
}

.faq a {
  color: #3498db;
  font-weight: bold;
}

.faq a:hover {
  color: #1e7fb9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-form-section, .map-section {
    margin-bottom: 30px;
    padding: 20px;
  }
}
