.reception-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
 
.left-logo {
  width: 100px;
  border-radius: 50%;
  height: 100px;
}
 
.right-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
 
 
.reception-container {
  background: linear-gradient(to right, #D7EAF0, #D7EAF0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

@media (max-width: 576px) {
  .appointments-col h5,
  .content-col h5.clickable {
    font-size: 0.9rem;
    padding: 6px;
  }

  .click-box {
    max-width: 100vw;
    padding: 10px;
  }

  .appointments-col {
    padding: 8px;
    max-height: 250px;
    flex: 1 1 100%;
  }

  .content-col {
    flex: 1 1 100%;
    padding: 8px;
  }

  .content-col h5.clickable {
    border-radius: 4px;
  }

  .content-col,
  .appointments-col {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .content-col h6 {
    font-size: 0.85rem;
    padding: 6px;
  }
}


@media (max-width: 768px) {
  .appointments-col h5,
  .content-col h5.clickable {
    font-size: 1.1rem;
    padding: 9px;
  }

  .content-col h5.clickable {
    border-radius: 5px;
  }
}