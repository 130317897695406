

.heading {
  text-align: center;
  /* margin-bottom: 20px; */
}

.manage-templates-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.button-panel {
  width: 35%;
  /* padding: 20px; */
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.upload-button {
  background: linear-gradient(90deg, #30abbb, #4bc0cf);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 45%;
}
.save-button {
  background: linear-gradient(90deg, #e4423d, #a12828);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 45%;
}

.upload-button:hover {
  background-color: #0056b3;
}

.custom-page {
  width: 30%;
  height: 80%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.header-section,
.footer-section {
  flex-shrink: 0;
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
  position: relative;
  overflow: hidden;
}

.footer-section {
  border-top: 1px solid #ddd;
}

.header-image,
.footer-image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  object-position: center;
  border-radius: 0;
}

.page-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #333;
  padding: 20px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .manage-templates-container {
    flex-direction: column;
  }

  .button-panel {
    width: 100%;
    padding: 10px;
    border-right: none;
    border-bottom: 1px solid #ddd;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
  }

  .custom-page {
    width: 100%;
    height: calc(100vh - 80px);
  }
}

@media (max-width: 480px) {
  .upload-button {
    padding: 8px 10px;
    font-size: 0.9rem;
  }

  .page-content {
    font-size: 1rem;
    padding: 10px;
  }
}
