@media (max-width: 576px) {
  .btn {
    padding: .375rem .75rem; /* Adjust padding for smaller screens */
  }
  .form-control {
    font-size: .875rem; /* Smaller font size for inputs on small screens */
  }
}
@media (max-width: 576px) {
  .btn {
    padding: 8px 12px;  /* Reduce padding to save space */
    font-size: 0.8rem;  /* Reduce font size for smaller displays */
    margin-left: 4px;
  }
}
 
@media (max-width: 768px) {
  .form-group-responsive {
    flex-direction: column; /* Stack vertically on smaller screens */
  }
 
  .form-control {
    border-radius: 5px; /* Round all corners for standalone input */
    margin-bottom: 8px; /* Add some space between input and button */
  }
 
  .search-button {
    width: 100%; /* Make button full width */
    margin: 0 auto; /* Center the button */
    border-radius: 5px; /* Round all corners for standalone button */
  }
}
 
 
@media (max-width: 768px) {
  .symptoms-header {
    flex-direction: row; /* Keep heading and search bar in a row */
    flex-wrap: wrap; /* Allow wrapping */
    align-items: center; /* Center-align items vertically */
  }
 
  .symptoms-header h5 {
    margin-bottom: 10px; /* Add spacing if needed */
    width : 100%;
  }
 
  .symptoms-header .form-control {
    margin-right: 20px; /* Space between search bar and heading */
    margin-bottom: 10px; /* Add spacing for wrap */
  }
 
  .symptoms-header button {
    align-self: flex-start; /* Align the button to the start */
    margin-bottom: 7px; /* Add spacing above */
  }
}
 
 
 
@media (max-width: 768px) {
  .prescription-header {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
    position: relative;
  }
 
  .prescription-header h5 {
    margin-bottom: 10px; /* Space below heading */
    align-self: flex-start; /* Ensure heading stays aligned left */
  }
 
  .button-row {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Make buttons take full width */
    margin-top: 10px; /* Space above button row */
  }
 
  .ms-3 {
    margin-right: auto; /* Align "Add Prescription" to the left */
    margin-top: 10px; /* Add top margin for spacing */
  }
 
  .upload-prescription-wrapper button {
    margin-left: auto; /* Align "Upload Prescription" to the right */
    margin-top: 57px;
  }
}

/* Default styles */
.responsive-toggles {
  display: flex;
  flex-wrap: wrap;
}

.responsive-toggles .toggle-item {
  flex: 1;
  margin-bottom: 10px;
}

/* Media query for 991px to 1400px */
@media (min-width: 991px) and (max-width: 1400px) {
  .responsive-toggles {
    flex-direction: column;
  }

  .responsive-toggles .toggle-item {
    width: 100%;
    flex: none;
  }
}
